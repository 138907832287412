import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { ResetCSS } from "../assets/css/style";
import Footer from "../containers/reusableItem/Footer";
import Navbar from "../containers/reusableItem/Navbar";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/seo";
import Banner from "../containers/reusableItem/reusableBanner/background";
import BannerContent from "../containers/reusableItem/reusableBanner/bannerContent";
import { agencyTheme } from "../theme/agency";
import { Modal } from "@redq/reuse-modal";
import {
  AgencyWrapper,
  GlobalStyle,
} from "../containers/reusableItem/agency.style";
import SAS from "../containers/AreaDistribusi/Features/FiturContact/FeatureContact";
import IMG from "../containers/AreaDistribusi/Features/Map/vimg";
import FeatureSectionB from "../containers/AreaDistribusi/Features/TerdapatDesc/TerdapatDesc";
import FeatureSection from "../containers/AreaDistribusi/Features";
import { useIntl } from "gatsby-plugin-intl";

export default () => {
  const intl = useIntl();

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title={intl.formatMessage({ id: "contactTitle" })} />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* <ContentWrapper> */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner>
            <BannerContent title={intl.formatMessage({ id: "contactTitle" })} />
          </Banner>
          {/* <a id="sektor"></a> */}
          <FeatureSection />
          {/* <a id="kategori"></a> */}
          <FeatureSectionB />
          <SAS />
          <IMG />
          <Footer />
          {/* </ContentWrapper> */}
        </AgencyWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
