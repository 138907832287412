import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Box from "../../../../reusecore/Box";
import Text from "../../../../reusecore/Text";
import Heading from "../../../../reusecore/Heading";
import FeatureBlock from "../../../../reusecore/FeatureBlocks";
import Container from "../../../../reusecore/UIHosting";
import FeatureSectionWrapper from "./featureSection.style";
import { Link } from 'gatsby';

const FeatureSection = ({
  row,
  col,
  featureNo,
  featureTitle,
  featureDescription,
  featuremap,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Features {
          id
          title
          maplink
          maplink2
          description
          descriptionkota
          no
          icon
          
        }
      }
    }
  `);

  return (
    <FeatureSectionWrapper id="service_section">
      <Container>
        <Box className="row" {...row}>
          {Data.saasJson.Features.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <FeatureBlock
                wrapperStyle={blockWrapperStyle}
                contentStyle={contentStyle}
                title={<Heading content={feature.title} {...featureTitle} />}
                description={
                  <Text content={feature.description} {...featureDescription} />
                }
                maplink={
                  <a href={feature.maplink2}>
                    <Text content={feature.maplink} {...featuremap}/>
                  </a>
                }
                descriptionkota={
                  <Text content={feature.descriptionkota} {...featureDescription} />
                }
                no={
                  <Text className="telp"
                    style={{ textDecoration: "underline" }}
                    content={feature.no}
                    {...featureNo}
                  />
                }

                className="saasFeature"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  featuremap: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "40px", "40px", "80px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "10px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#eb3434",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 4, 1 / 4],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ["30px", "20px", "20px", "25px"],
  },
  // feature icon default style
  iconStyle: {
    width: ["70px", "75px", "75px", "84px"],
    height: ["70px", "75px", "75px", "84px"],
    borderRadius: "50%",
    bg: "#93d26e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ["32px", "36px"],
    color: "#ffffff",
    overflow: "hidden",
    mb: ["20px", "20px", "20px", "30px"],
    borderBottomLeftRadius: "50%",
  },
  // feature content default style
  contentStyle: {
    textAlign: "left",
  },
  // feature title default style
  featureTitle: {
    fontSize: "44px",
    fontWeight: "600",
    color: "#000000",
    lineHeight: "1.5",
    mb: ["10px", "10px", "10px", "20px"],
    letterSpacing: "-0.020em",
  },
  // feature description default style
  featureDescription: {
    fontSize: "36px",
    fontWeight: "200",
    lineHeight: "1.75",
    color: "#000000",
  },

  featureNo: {
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "1.75",
    color: "#000000",
  },
  featuremap :
  {
    fontSize: "15px!important",
    fontWeight: "600!important",
    lineHeight: "1.75",
    color: "green",
  }
};

export default FeatureSection;
