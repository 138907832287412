import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import Box from "../../../reusecore/Box";
import Text from "../../../reusecore/Text";
import Container from "../../../reusecore/UI/Container";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const FeatureSection = ({ sectionWrapper, secTitleWrapper, secText, intl }) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...secTitleWrapper}>
          <Fade bottom cascade>
            <Text
              {...secText}
              content={<FormattedMessage id="contactTitle" />}
            />
          </Fade>
        </Box>
      </Container>
    </Box>
  );
};

FeatureSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  featureItemHeading: PropTypes.object,
  featureItemDes: PropTypes.object,
};

FeatureSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["0px", "2rem", "2rem", "2rem"],
    pb: ["0px", "2rem", "2rem", "2rem"],
    id: "feature_section",
  },
  secTitleWrapper: {
    mb: ["0px", "0px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: "0.2em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "1.5rem",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  col: {
    className: "col",
    width: [1, 1 / 2, 1 / 3, 1 / 3],
    pr: "15px",
    pl: "15px",
    mb: "30px",
  },
  featureItemHeading: {
    fontSize: ["18px", "18px", "16px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    lineHeight: "1.5",
    mb: ["10px", "10px", "10px", "10px"],
    letterSpacing: "-0.020em",
    maxWidth: ["auto", "auto", "auto", "180px"],
  },
  featureItemDes: {
    fontSize: ["14px", "14px", "14px", "15px"],
    lineHeight: "1.75",
    color: "#343d48cc",
    mb: ["20px", "20px", "20px", "20px"],
  },
};

export default injectIntl(FeatureSection);
