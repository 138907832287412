import React from "react";
import Fade from "react-reveal/Fade";
import Container from "../../../../reusecore/deskripsiContainer";
import Text from "../../../../reusecore/Text";

import SectionWrapper, { SectionHeader } from "./Terdapat.style";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const Services = () => {
  return (
    <SectionWrapper id="services">
      <div className="deskripsi">
        <Container width="1590px">
          <Fade up delay={100}>
            <SectionHeader>
              <Text content={<FormattedMessage id="contactDesc" />} />
            </SectionHeader>
          </Fade>
        </Container>
      </div>
    </SectionWrapper>
  );
};

export default injectIntl(Services);
