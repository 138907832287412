import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Box from "../../../../reusecore/Box";
import Container from "../../../../reusecore/UI/Container";
import VideoSectionWrapper from "./MapSection.style";

const VideoSection = ({ imageWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      featureImage: file(relativePath: { eq: "image/new/peta.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <VideoSectionWrapper id="videoSection">
      <Container>
        <Box className="figure" {...imageWrapper}>
          <Image
            fluid={
              (Data.featureImage !== null) | undefined
                ? Data.featureImage.childImageSharp.fluid
                : {}
            }
            alt="Video Preview Image"
          />
        </Box>
      </Container>
    </VideoSectionWrapper>
  );
};

// VideoSection style props
VideoSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
  imageWrapper: PropTypes.object,
};

// VideoSection default style
VideoSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "56px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#10ac84",
    mb: "10px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  // button default design
  buttonStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    marginTop: "25rem",
    fontWeight: "500",
  },
  imageWrapper: {
    display: " block",
  },
};

export default VideoSection;
